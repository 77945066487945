



























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import { deleteWorkflow, getWorkflow, getWorkflowMessages, updateWorkflow } from '@/api/consoleApi/workflows'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import EditNameModal from '@/modules/workflows/components/EditNameModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
import { get } from 'lodash'
import { getWorkflowStatistics } from '@/api/consoleApi/reports'
import { formatNumber } from '@/utils/formatter'
import html2pdf from 'html2pdf.js'
import VsWorkflowStatisticsCard from '@/modules/workflows/components/VsWorkflowStatisticsCard/Index.vue'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'WorkflowStatistics',
    components: {
        VsSectionHeader,
        VsContainer,
        VsTabsHeading,
        VsDropdownButton,
        EditNameModal,
        VsConfirm,
        VsLoader,
        VsWorkflowStatisticsCard,
    },
})
export default class extends Vue {
    loading = false
    loadingStatistics = false
    workflow: Workflow | null = null
    $refs!: any
    list = null
    private tabOpen = ''
    private total = 0
    private messages: any[] = []
    private missingSteps: string[] = []
    private statistics: {
        total_sent_email: number
        total_sent_sms: number
        total_workflow_completed: number
    }| null = null

    pagination: any = {
        page: 1,
        itemsPerPage: 5,
        orderBy: null,
    }

    get activeTab () {
        return this.$route.name
    }

    get tabs () {
        const tabs = [
            {
                label: 'Riepilogo',
                id: 'editWorkflow',
                to: {
                    name: 'editWorkflow',
                },
            },
            {
                label: 'Statistiche',
                id: 'workflowStatistics',
                to: {
                    name: 'workflowStatistics',
                },
            },
            {
                label: 'Coda di invio',
                id: 'workflowQueue',
                to: {
                    name: 'workflowQueue',
                },
            },
        ]

        return tabs
    }

    get workflowId () {
        return this.$route?.params?.workflowId || ''
    }

    get badgeStatusAspect () {
        switch (this.workflow?.status || null) {
            case 'ready':
                return 'success'
            case 'pause':
                return 'warning'
            case 'draft':
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        if (!this.workflow?.status) return ''
        switch (this.workflow?.status || null) {
            case 'pause':
                return 'pause'
            case 'ready':
                return 'double-check'
            case 'draft':
                return 'edit'
        }
    }

    get user () {
        return UserModule.user
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    beforeMount () {
        this.getWorkflow()
        this.getWorkflowStatistics()
        this.getMessageStatistics()
    }

    async getWorkflow () {
        this.missingSteps = []
        try {
            const resp = await getWorkflow(this.workflowId, { include: 'recipient.store,messages,messages.sms,messages.email.footer' })
            this.workflow = resp.data.data
        } catch (e) {
            const code = get(e, 'response.status', null)
            if (code === 404) {
                this.$root.$vsToast({
                    timeout: 3000,
                    heading: 'Marketing automation non trovata',
                    aspect: VsToastAspectEnum.alert,
                })
                this.$router.replace({ name: 'workflows' })
                return
            }
            console.log(e)
        }
    }

    private buildParams () {
        const params: any = {
            orderBy: 'priority',
            sortedBy: 'asc',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
            include: 'statistics',
        }

        return params
    }

    @Watch('pagination', { deep: true, immediate: false })
    private async getMessageStatistics () {
        this.loadingStatistics = true
        const resp = await getWorkflowMessages(this.workflowId, this.buildParams())
        this.messages = resp.data.data
        this.total = resp.data.meta.pagination.total
        if (resp.data.meta.pagination.current_page > resp.data.meta.pagination.total_pages) {
            this.pagination.page = 1
        }
        this.loadingStatistics = false
    }

    private async getWorkflowStatistics () {
        this.loading = true
        try {
            const resp = await getWorkflowStatistics(this.workflowId)
            this.statistics = resp.data.data
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    async editAutomationName (event: any) {
        this.loading = true
        try {
            await updateWorkflow(this.workflow?.id || '', {
                name: event.name,
            })
            this.getWorkflow()
            this.$root.$vsToast({
                heading: 'Nome modificato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del nome',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async deleteWorkflow () {
        if (!this.workflow) return ''
        try {
            await this.$refs.confirmDelete.openConfirm()
        } catch (e) {
            return
        }

        this.loading = true
        try {
            await deleteWorkflow(this.workflowId)
            this.$root.$vsToast({
                heading: 'Flusso eliminato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$router.replace({
                name: 'workflows',
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'eliminazione del flusso',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private formatNumber (value: number) {
        return formatNumber(value)
    }

    private openMessageStatistics (message: any) {
        this.$router.push({
            name: 'workflowMessageStatistics',
            params: {
                workflowId: this.workflowId,
                messageId: message.id,
            },
        })
    }

    async downloadPdf () {
        this.loading = true
        const opt = {
            filename: `${this.workflow?.name}_general_statistics.pdf`,
            image: { type: 'jpeg', quality: 1 },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
            },
        }
        setTimeout(async () => {
            await html2pdf().from(this.$refs.printContent.$el).set(opt).save()
            this.$refs.printContent.$el.style.width = '100%'
            this.loading = false
        }, 1000)
    }
}
